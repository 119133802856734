<template>
<b-modal v-model="show" title="Edit Timeline" ok-title="Submit" :hide-footer="true">
        <form @submit.prevent="$emit('submit')">
            <b-form-group>
                <b-form-textarea
                    v-model="modelContent"
                    placeholder="Add a Content"
                    rows="3"
                ></b-form-textarea>
            </b-form-group>
            <b-form-group align="right">
                <div class="d-flex justify-content-between">
                    <b-form-checkbox v-model="modelDisableComments" name="check-button" switch :unchecked-value="0" :value="1">
                        Disable comments
                    </b-form-checkbox>
                    <div>
                        <b-button variant="primary" type="submit" size="sm">
                            Submit
                        </b-button>
                    </div>
                </div>
            </b-form-group>
        </form>
    </b-modal>
</template>

<script>
    export default {
        props: {
            show: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            value: {
                type: Object,
                default: () => ({})
            }
        },
        computed: {
            modelContent: {
                get () {
                    return this.value.content
                },
                set (content) {
                    this.$emit('input', { ...this.value, content })
                }
            },
            modelDisableComments: {
                get () {
                    return this.value.disable_comments
                },
                set (disableComments) {
                    this.$emit('input', { ...this.value, disable_comments: disableComments })
                }
            }
        }
    }
</script>
