<template>
    <div class="card lg:mx-0 p-4" uk-toggle="target: #create-post-modal">
        <div class="flex space-x-3">
            <img :src="user && user.images !== '' && user.images !== null ? user.images : require('@/assets/images/avatars/avatar-1.jpg')" class="w-10 h-10 rounded-full">
            <div class="bg-gray-100 rounded-full relative dark:bg-gray-800 border-t" style="width: 100%;">
                <input :disabled="submitLoading" placeholder="What’s on your mind?" v-model="content" class="bg-gray-100 hover:bg-gray-200 flex-1 h-10 px-6 rounded-full" @keyup.enter="createTimeline">
                <div class="absolute bottom-0 top-0 flex items-center right-3 text-xl" style="margin: 5px;">
                    <b-spinner v-if="submitLoading" small />
                    <a v-else href="javascript:void(0)">
                        <div class="position-relative input-file">
                            <input type="file" @change="onFileChange">
                            <Icon icon="bx:bx-image" class="hover:bg-gray-200 fs-30 p-1.5 rounded-full" />
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div v-if="attachments.length > 0" class="mt-4">
            <img v-for="(item, index) in attachments" :src="getFileUrl(item)" :key="index" class="img-thumbnail w-25">
        </div>
        <!-- <div class="grid grid-flow-col pt-3 -mx-1 -mb-1 font-semibold text-sm">
            <div class="hover:bg-gray-100 flex items-center p-1.5 rounded-md cursor-pointer">
                <div class="position-relative input-file">
                    <input type="file" @change="onFileChange">
                    <span class="d-flex align-items-center">
                        <svg class="bg-blue-100 h-9 mr-2 p-1.5 rounded-full text-blue-600 w-9 -my-0.5 hidden lg:block" data-tippy-placement="top" title="Tooltip" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                        Photo/Video
                    </span>
                </div>
            </div>
            <div class="hover:bg-gray-100 flex items-center p-1.5 rounded-md cursor-pointer">
                <svg class="bg-green-100 h-9 mr-2 p-1.5 rounded-full text-green-600 w-9 -my-0.5 hidden lg:block" uk-tooltip="title: Messages ; pos: bottom ;offset:7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" title="" aria-expanded="false"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path></svg>
                Tag Friend
            </div>
            <div class="hover:bg-gray-100 flex items-center p-1.5 rounded-md cursor-pointer">
            <svg class="bg-red-100 h-9 mr-2 p-1.5 rounded-full text-red-600 w-9 -my-0.5 hidden lg:block" uk-tooltip="title: Messages ; pos: bottom ;offset:7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" title="" aria-expanded="false"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path></svg>
            Fealing /Activity
            </div>
        </div> -->
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Icon } from '@iconify/vue2'

    export default {
        components: {
            Icon
        },
        computed: {
            ...mapState('auth', ['user'])
        },
        methods: {
            ...mapActions('timelines', ['create']),
            createTimeline () {
                this.submitLoading = true
                this.create({ group_id: null, content: this.content, attachments: this.attachments })
                    .then((data) => {
                        this.content = ''
                        this.attachments = []
                        this.$emit('created', data)
                    })
                    .finally(() => {
                        this.submitLoading = false
                    })
            },
            onFileChange (event) {
                this.attachments = [...event.target.files]
            },
            getFileUrl (file) {
                return URL.createObjectURL(file)
            }
        },
        data () {
            return {
                submitLoading: false,
                content: '',
                attachments: [],
                currentPage: 0,
                perPage: 5,
                loadMoreLoading: false,
                isLastPage: false
            }
        }
    }
</script>
