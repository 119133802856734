<template>
    <div>
        <div class="card lg:mx-0 uk-animation-slide-bottom-small mt-4">
            <div class="flex justify-between items-center lg:p-4 p-2.5">
                <div class="flex flex-1 items-center space-x-4">
                    <a href="#">
                        <img :src="data.author.images !== '' && data.author.images !== null ? data.author.images : require('@/assets/images/avatars/avatar-1.jpg')" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                    </a>
                    <div class="flex-1 font-semibold capitalize">
                        <a href="#" class="text-black dark:text-gray-100">{{ data.author.fullname }}</a>
                        <div class="text-gray-700 flex items-center space-x-2 time-post"><span>{{ formatDateFromNow(data.created_at) }}</span><Icon icon="bx:bx-user" /></div>
                    </div>
                </div>
                <div v-if="data.is_author || data.is_admin">
                    <b-dropdown class="dropdown-no-style">
                        <template #button-content>
                            <i class="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"></i>
                        </template>
                        <b-dropdown-item v-if="data.is_author" @click="onEdit">
                            <i class="uil-edit-alt mr-1"></i>  Edit Post
                        </b-dropdown-item>
                        <b-dropdown-item @click="onDelete">
                            <i class="uil-trash-alt mr-1"></i>  Delete
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <div class="px-4 space-y-3" :class="data.attachments && data.attachments.length > 0 ? 'pb-4' : 'py-0'" v-linkified>
                {{  data.content }}
            </div>

            <div v-if="data.attachments && data.attachments.length > 0" class="timeline-image-content">
                <a href="javascript:void(0)" v-for="item in data.attachments" :key="item.id" @click="popupImage(item.file_url)">
                    <img :src="item.file_url" alt="" class="max-h-96 w-full object-cover">
                </a>
            </div>

            <div class="p-4 space-y-3">

                <div class="flex justify-content-between lg:font-bold">
                    <div class="flex space-x-4 ">
                        <a href="#" class="flex items-center space-x-2" :style="{ color: computedIsLiked ? '#2e74b0' : '' }" @click.prevent="onLike">
                            <div class="p-2 rounded-full text-black lg:bg-gray-100 dark:bg-gray-600">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" :fill="computedIsLiked ? '#2e74b0' : 'currentColor'" width="22" height="22" class="dark:text-gray-100">
                                    <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                </svg>
                            </div>
                            <div> Like</div>
                        </a>
                        <a v-if="!data.disable_comments" :href="scrollTo" v-scroll-to="scrollTo" class="flex items-center space-x-2" @click.prevent="showComment = !showComment">
                            <div class="p-2 rounded-full  text-black lg:bg-gray-100 dark:bg-gray-600">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                    <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            <div>{{ data.comments_count }} Comment</div>
                        </a>
                    </div>
                    <div :right="true" class="mt-2">
                        {{ data.likes_count }} people like
                    </div>
                    <!-- <b-dropdown variant="link" toggle-class="text-decoration-none history-filter" no-caret :right="true">
                        <template #button-content>
                            <div class="flex items-center space-x-2 flex-1 justify-end">
                                <div class="p-2 rounded-full text-black lg:bg-gray-100 dark:bg-gray-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                                        <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                                    </svg>
                                </div>
                                <div class="dropdown-share-trigger">Share</div>
                            </div>
                        </template>
                        <div class="flex justify-content-center py-2 px-3 space-x-3">
                            <ShareNetwork
                                v-for="network in shareNetworks"
                                :key="network.name"
                                :network="network.name"
                                :title="data.content"
                                :url="shareUrl"
                            >
                                <Icon :icon="network.icon" />
                            </ShareNetwork>
                            <a href="" @click.prevent="copyUrl">
                                <Icon icon="uil:copy-alt" />
                            </a>
                        </div>
                    </b-dropdown> -->
                </div>
                <div class="space-y-3" v-show="showComment">
                    <comment-list
                        :comments="data.comments"
                        :comments-count="data.comments_count"
                        :likes="data.likes"
                        :likes-count="data.likes_count"
                        :disable-comment="data.disable_comments"
                        :add-comment="onComment"
                        :input-id="`timeline-comment-${data.id}`"
                    />
                </div>
            </div>
        </div>
        <form-timeline :loading="editForm.loading" :show="editForm.show" v-model="editForm.data" @submit="updateTimeline" />
    </div>
</template>

<script>
    import { Icon } from '@iconify/vue2'
    import CommentList from '@/components/CommentList.vue'
    import { mapActions } from 'vuex'
    import FormTimeline from '@/components/FormTimeline'
    import copy from 'copy-to-clipboard'
    export default {
        props: {
            data: {
                type: Object,
                default: () => ({
                    author: {}
                })
            }
        },
        data () {
            return {
                showComment: false,
                editForm: {
                    loading: false,
                    show: false,
                    data: {
                        disable_comments: false,
                        content: ''
                    }
                },
                local: {
                    changed: false,
                    is_liked: false
                },
                shareNetworks: [
                    {
                        name: 'facebook',
                        icon: 'uil:facebook-f'
                    },
                    {
                        name: 'twitter',
                        icon: 'uil:twitter'
                    },
                    {
                        name: 'whatsapp',
                        icon: 'uil:whatsapp'
                    },
                    {
                        name: 'telegram',
                        icon: 'uil:telegram-alt'
                    }
                ]
            }
        },
        computed: {
            computedIsLiked () {
                if (this.local.changed) return this.local.is_liked
                return this.data.is_liked
            },
            shareUrl () {
                return window.location.href
            },
            scrollTo () {
                if (this.showComment) return `#timeline-comment-${this.data.id}`

                return ''
            }
        },
        methods: {
            ...mapActions('timelines', ['addComment', 'delete', 'enableComment', 'disableComment', 'update', 'like', 'unlike']),
            copyUrl () {
                copy(`${this.data.content} \n ${this.shareUrl}`)
                this.$swal({
                    icon: 'success',
                    text: 'Url copied to clipboard'
                })
            },
            onComment ({ content, parentId, attachments }) {
                return this.addComment({
                    slug: this.data.id,
                    content,
                    parentId,
                    attachments
                })
            },
            onLike () {
                if (this.computedIsLiked) {
                    this.unlike(this.data.id)
                        .then(() => {
                            this.local.changed = true
                            this.local.is_liked = false
                            this.data.likes_count -= 1
                        })
                } else {
                    this.like(this.data.id)
                        .then(() => {
                            this.local.changed = true
                            this.local.is_liked = true
                            this.data.likes_count += 1
                        })
                }
            },
            onEdit () {
                this.editForm.show = true
                this.editForm.data = { ...this.data }
            },
            onDelete () {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.delete(this.data.id).then(() => {
                            this.$swal(
                                'Deleted!',
                                'Your post has been deleted.',
                                'success'
                            )
                        })
                    }
                })
            },
            popupImage (url) {
                this.$swal({
                    imageUrl: url,
                    showCloseButton: true,
                    showConfirmButton: false
                })
            },
            updateTimeline () {
                this.editForm.loading = true
                this.update({
                    id: this.data.id,
                    data: {
                        group_id: this.data.group_id,
                        disable_comments: this.editForm.data.disable_comments,
                        content: this.editForm.data.content
                    }
                })
                    .then(() => {
                        this.editForm.show = false
                        this.editForm.data = { content: '' }
                    })
                    .finally(() => {
                        this.editForm.loading = false
                    })
            }
        },
        components: { FormTimeline, Icon, CommentList }
    }
</script>
