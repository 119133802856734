<template>
  <div class="flex md:space-x-6 space-x-4 md:py-5 py-3 relative flex-center">
      <div class="flex-1 space-y-2">
            <div class="d-flex">
                <router-link :to="{ name: 'GroupDetail', params: {code: data.slug } }" class="d-inline circle overflow-hidden relative shadow-sm w-12 h-12" v-if="styles == 'feed'">
                    <img :src="data.images" alt="" class="w-full h-full absolute inset-0 object-cover">
                </router-link>
                <router-link :to="{ name: 'GroupDetail', params: {code: data.slug } }" class="md:text-xl font-semibold line-clamp-2 align-self-center ml-2" v-if="styles == 'feed'">{{ data.title }}</router-link>
            </div>
            {{ data.date }}
            <p class="leading-6 pr-4 line-clamp-2 md:block hidden" v-if="styles == 'feed'">{{ limitString(data.description, 200) }}</p>
            <div class="flex flex-wrap mt-2 space-x-2" v-if="styles == 'feed'">
                <div class="flex items-center -space-x-2 -mt-1">
                <img alt="Image placeholder" v-for="member in data.member.slice(0, 2)" :key="member.id" :src="member.user.images ? member.user.images : $placeholder" class="border-2 border-white rounded-full w-7 h-7">
                </div>
                <div class="flex-1 leading-4 text-sm">
                <div v-html="data.text_member"></div>
                </div>
            </div>
            <!-- <div class="flex items-center justify-between" v-if="styles == 'feed'">
                <div class="flex space-x-3">
                    <button @click="actionJoin" class="md:flex items-center justify-center h-9 px-8 rounded-md border hidden bg-blue-600 text-white" v-if="!data.user_id && data.waiting_approval !== 1">Join</button>
                    <button class="bg-gray-200 flex flex-1 h-8 items-center px-4 justify-center rounded-md capitalize" v-if="data.waiting_approval === 1">Waiting Approval</button>
                </div>
            </div> -->
      </div>
  </div>
</template>
<script>
    import axios from 'axios'

    export default {
        name: 'list_blog',
        props: ['data', 'styles', 'type', 'small'],
        components: {},
        methods: {
            actionJoin () {
                const $this = this
                axios.get('group/join/' + this.$props.data.slug).then(function (response) {
                    var titleAlert = 'Waiting Approval'
                    if (response.data.verification === 1) {
                        titleAlert = 'Success Join Group'
                    }
                    $this.$swal({
                        imageUrl: require('@/assets/images/vector-success.png'),
                        imageWidth: 400,
                        title: titleAlert,
                        text: response.data.message
                    }).then(function () {
                        if (response.data.verification === 1) {
                            $this.$router.push({ name: 'GroupDetail', params: { code: $this.$props.data.slug } })
                        } else {
                            $this.data.waiting_approval = 1
                        }
                    })
                }).catch(function () {
                    $this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something Wrong'
                    })
                })
            }
        }
    }
</script>
