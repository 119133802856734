<template>
    <div id="feed">
        <div class="mcontainer full-width">
            <b-row>
                <b-col sm="12">
                    <img src="@/assets/images/banner.png" alt="BANNER" style="margin-bottom: 40px;">
                </b-col>
                <b-col sm="12" lg="8">
                    <timeline-form @created="fetchFeedTimeline" />
                    <div>
                        <div v-if="data.length > 0">
                            <timeline-item v-for="item in data" :key="item.id" :data="item" />
                        </div>
                        <div v-else class="text-center py-20">
                            <empty>
                                <p class="pt-2">Timeline is empty</p>
                            </empty>
                        </div>

                        <div v-if="!timelines.isLastPage" class="load-more-loading text-center py-4 mt-3">
                            <b-spinner v-if="timelines.loadMoreLoading" />
                            <a href="" v-else @click.prevent="fetchFeedTimeline" class="card py-2 px-4 inline">Load More</a>
                        </div>
                    </div>
                </b-col>
                <b-col sm="12" lg="4" class="d-none d-lg-block">
                    <div>
                        <div class="flex justify-between relative md:mb-4 mb-3">
                            <div class="flex-1">
                                <h4 class="text-lg font-semibold"> Related Resource </h4>
                            </div>
                            <router-link to="/app/resources" class="flex items-center justify-center h-9 lg:px-5 px-3 space-x-1.5 absolute right-0">
                                <span> Lihat Semua </span>
                            </router-link>
                        </div>

                        <div class="relative">
                            <div class="px-1 py-3">
                                <CardFeedVideo v-for="resource in resources" :key="resource.id" :data="resource" />
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between relative md:mb-4 mb-3">
                        <h2 class="text-lg font-semibold"> Jelajahi Grup </h2>
                        <router-link to="/app/group" class="flex items-center justify-center h-9 lg:px-5 px-3 space-x-1.5 absolute right-0">
                        <span> Lihat Semua </span>
                        </router-link>
                    </div>
                    <div class="divide-y">
                        <ListFeedBlog v-for="group in groups" :key="group.id" :data="group" :styles="'feed'" :small="false" />
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import CardFeedVideo from '@/components/CardFeedVideo'
    import ListFeedBlog from '@/components/ListFeedBlog'
    import TimelineForm from '@/components/TimelineForm'
    import TimelineItem from '@/components/TimelineItem.vue'
    import Empty from '@/components/Empty.vue'
    import axios from 'axios'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Feed',
        data () {
            return {
                resources: [],
                groups: [],
                timelines: {
                    currentPage: 0,
                    perPage: 5,
                    loadMoreLoading: false,
                    isLastPage: false
                }
            }
        },
        components: {
            CardFeedVideo,
            ListFeedBlog,
            TimelineForm,
            TimelineItem,
            Empty
        },
        computed: {
            ...mapState('timelines', ['data'])
        },
        created () {
            this.fetchData()
            this.fetchFeedTimeline()
        },
        watch: {
            $route: 'fetchData'
        },
        methods: {
            ...mapActions('timelines', ['fetch']),
            fetchData () {
                const $this = this
                this.loading = false
                axios.get('group?limit=5').then(function (res) {
                    $this.groups = res.data.result
                    $this.loading = true
                })
                axios.get('resource?limit=5').then(function (res) {
                    $this.resources = res.data.result.data
                })
            },
            fetchFeedTimeline () {
                this.timelines.loadMoreLoading = true
                this.fetch({ limit: this.timelines.perPage, page: this.timelines.currentPage + 1, append: true })
                    .then(res => {
                        if (res.currentPage === res.lastPage) {
                            this.timelines.isLastPage = true
                        }
                        this.timelines.currentPage = res.currentPage
                    })
                    .finally(() => {
                        this.timelines.loadMoreLoading = false
                    })
            }
        }
    }
</script>
