<template>
  <div class="mb-5 d-flex">
      <router-link :to="{ name: 'DetailResource', params: {code: data.slug } }" class="md:h-36 h-28 w-half overflow-hidden relative block" style="min-width: 50%;">
        <img :src="data.images" alt="" class="w-full h-full absolute inset-0 object-cover">
        <span class="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs" v-if="data.duration">{{ data.duration }}</span>
        <img src="@/assets/images/icon-play.svg" class="w-12 h-12 uk-position-center" alt="" v-if="data.link !== ''">
      </router-link>
      <div class="px-3">
        <router-link :to="{ name: 'DetailResource', params: {code: data.slug } }" class="font-semibold line-clamp-2">{{ data.title }}</router-link>
        <div class="pt-2">
          <router-link :to="{ name: 'DetailResource', params: {code: data.slug } }" class="text-sm">{{ data.created_by.fullname }}</router-link>
          <div class="flex space-x-2 items-center text-xs" v-if="data.type">
            <div>{{ data.type }}</div>
            <div class="md:block hidden">·</div>
            <div>{{ data.category.title }}</div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
    export default {
        name: 'card_video',
        props: ['data', 'type'],
        methods: {
            deleteItem () {
                this.$emit('delete-item')
            }
        }
    }
</script>
